<template>
  <div>
    <!-- <?xml version="1.0" encoding="UTF-8"?> -->
    <svg
      width="13px"
      height="13px"
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
      <title>068E2CBB-A7C1-4AE2-9DA6-0ACEFD531A97</title>
      <desc>Created with sketchtool.</desc>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Spec" transform="translate(-921.000000, -554.000000)" fill="#EFEFF4">
          <g id="online" transform="translate(921.071429, 554.918717)">
            <path
              d="M6,0 C9.3137085,0 12,2.6862915 12,6 C12,9.3137085 9.3137085,12 6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 Z M8.72334515,4.05682459 C8.46748356,3.800963 8.05264996,3.800963 7.79678837,4.05682459 L7.79678837,4.05682459 L4.80934374,7.04383548 L3.71243306,5.94725148 C3.45657147,5.69138989 3.04173787,5.69138989 2.78587628,5.94725148 C2.53001469,6.20311307 2.53001469,6.61794667 2.78587628,6.87380826 L2.78587628,6.87380826 L4.27162908,8.35956106 C4.38834372,8.4762757 4.53813803,8.53974942 4.69051964,8.549741 C4.90974601,8.60921995 5.15383483,8.5528917 5.32597028,8.38075624 L5.32597028,8.38075624 L8.72334515,4.98338138 C8.97920674,4.72751979 8.97920674,4.31268618 8.72334515,4.05682459 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss">
</style>